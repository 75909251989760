<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="所属社区">
					<el-select clearable v-model="formInline.communityCode" placeholder="请选择社区">
						<el-option v-for="item in communitys" :key="item.levelcode" :label="item.name"
							:value="item.levelcode">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否为特殊困难群体">
          <el-select style="width: 220px;" clearable v-model="formInline.health" placeholder="请选择是否为特殊困难群体">
            <el-option label="否" value="否"></el-option>
            <el-option label="低保边缘户" value="低保边缘户"></el-option>
            <el-option label="低保户" value="低保户"></el-option>
            <el-option label="残障" value="残障"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
				<el-form-item label="排序种类">
					<el-select clearable v-model="formInline.order" placeholder="请选择排序种类">
						<el-option v-for="item in orders" :key="item.value" :label="item.name"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-refresh">刷新</el-button>
					<el-button type="primary" v-if="tableData.length>0" @click="exportXcle"
						icon="el-icon-download">导出</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="tablebox">
			<el-table v-loading="loading" :data="tableData" style="width: 100%"
				:header-cell-style="{ fontSize: '14px', color: '#444'}">
				<el-table-column prop="no" label="序号" width="120" align="center">
				</el-table-column>
				<el-table-column prop="name" label="姓名" align="center">
				</el-table-column>
				<el-table-column prop="id_no" label="身份证" align="center">
				</el-table-column>
				<el-table-column prop="communityname" label="社区" align="center">
				</el-table-column>
				<el-table-column prop="regionname" label="小区" align="center">
				</el-table-column>
				<el-table-column prop="cpc_branch_name" label="党支部名称" align="center">
				</el-table-column>
				<el-table-column prop="contact_mobile" label="联系电话" align="center">
				</el-table-column>
				<el-table-column prop="health" label="是否为特殊困难群体" align="center">
				</el-table-column>
				<el-table-column prop="othernum" label="特殊困难群体任务参与次数" align="center">
				</el-table-column>
				<el-table-column prop="point" label="总消费积分" align="center">
				</el-table-column>
				<el-table-column prop="usablepoint" label="可用消费积分" align="center">
				</el-table-column>
				<el-table-column prop="volpoint" label="总荣誉积分" align="center">
				</el-table-column>
				<el-table-column prop="usablevolpoint" label="可用荣誉积分" align="center">
				</el-table-column>
				<el-table-column prop="actnum" label="活动参与次数" align="center">
				</el-table-column>
				<el-table-column prop="applynum" label="任务参与次数" align="center">
				</el-table-column>
			</el-table>

			<div class="paginationbox">
				<el-pagination @size-change="handleSizeChange" :hide-on-single-page="true" @current-change="handleCurrentChange"
					:current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize"
					layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		partyMemberRankingList,
		partyMemberRankExportExcel
	} from '@/api/reportStatistics'
	import {
		communityOfUser
	} from "@/api/system";
	export default {
		data() {
			return {
				loading: false,
				formInline: {
					communityCode: '',
					health: '',
					order: 'othernum'
				},
				communitys: [],
				orders:[{
					name: '特殊困难群体任务参与次数',
					value: 'othernum'
				},{
					name: '总消费积分',
					value: 'point'
				},{
					name: '总荣誉积分',
					value: 'volpoint'
				},{
					name: '活动参与次数',
					value: 'actNum'
				},{
					name: '任务参与次数',
					value: 'applyNum'
				}],
				tableData: [],
				headLabel: [],
				currentPage: 1,
				fenye: {
					totalnum: 0,
					pagesizes: [10, 20, 30, 40, 50],
					pagesize: 10
				},
			};
		},

		components: {},

		computed: {},

		mounted() {
			this.getList();
			this.getCommunityOfUser();
		},

		methods: {
			getCommunityOfUser() {
				communityOfUser({
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						this.communitys = res.detail;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			onSearch() {
				this.currentPage = 1;
				this.getList();
			},
			//切换页面条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			//切换分页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},

			//获取商户近一个月每天获取积分
			getList() {
				this.loading = true;
				partyMemberRankingList({
					order: this.formInline.order,
					communitycode: this.formInline.communityCode,
					health: this.formInline.health,
					pageNum: this.currentPage,
					pageSize: this.fenye.pagesize,
				}).then(res => {
					if (res.result == 200) {
						let detail = res.detail;
						let list = detail.list;
						this.tableData = list;
						this.fenye.totalnum = detail.totalRow;
						this.tableData = list;
					}
					this.loading = false;
				})
			},
			//导出表格
			exportXcle() {
				partyMemberRankExportExcel({
					order: this.formInline.order,
					health: this.formInline.health,
					communitycode: this.formInline.communityCode,
				}).then(res => {
					const content = res;
					const blob = new Blob([content]) //构造一个blob对象来处理数据
					const fileName = '党员排行报表.xlsx';
					const link = document.createElement('a') //创建a标签
					link.download = fileName //a标签添加属性
					link.style.display = 'none'
					link.href = URL.createObjectURL(blob)
					document.body.appendChild(link)
					link.click() //执行下载
					URL.revokeObjectURL(link.href) //释放url
					document.body.removeChild(link) //释放标签
				})
			},
		},
	};
</script>
<style lang="scss" scoped>
	.paginationbox {
		text-align: right;
		margin-top: 20px;
	}
</style>